import React, { useState, useRef } from 'react';
import DOMPurify from 'dompurify';
import './index.css';
import { GET_BANK_TOKEN, GET_AUTH_TOKEN } from '../util/api';
import { ACCOUNT_NUMBER_VALIDATION_ERROR, ACCOUNT_NUMBER_LENGTH_VALIDATION_ERROR, COMMON_API_ERROR } from '../util/constant';

const IndexPage = () => {
  const [accNo, setAccNo] = useState('');
  const [showReqErrorMsg, setShowReqErrorMsg] = useState(false);
  const [showMinMaxErrorMsg, setShowMinMaxErrorMsg] = useState(false);
  const inputRef = useRef();
  const [showCommonError, setShowCommonError] = useState(false);
  const [commonErrorMsg, setCommonErrorMsg] = useState('');
  // const [tokenData, setTokenData] = useState('');
  const [specificErrorMsg, setSpecificErrorMsg] = useState('');
  const [authToken, setAuthToken] = useState('');
  // const [popupPos, setPopupPos] = useState("");

  const accNoMax = "17";
  const path = `${process.env.GATSBY_PCI_API_URL}` ? `${process.env.GATSBY_PCI_API_URL}` : process.env.GATSBY_PCI_API_URL;
  const apiKey = `${process.env.GATSBY_X_API_KEY}` ? `${process.env.GATSBY_X_API_KEY}` : process.env.GATSBY_X_API_KEY;
  const isWindow = typeof window !== "undefined";
  
  if (isWindow) { 
    window.onmessage = function (e) {
      if (e.data === 'msg') {
        getAuthToken(e);
      } else if(e.data === 'default') {
        resetValues();
      }
    };
  }

  const authHaders = {
    'x-api-key': apiKey
  }

  const tokenHeaders = {
    'Accept': '*/*',
    'Content-Type': 'application/json'
  }

  const getAuthToken = (e) => {
    if (accNo !== "" && !showReqErrorMsg && !showMinMaxErrorMsg) {
    setSpecificErrorMsg('');
    fetch(path + GET_AUTH_TOKEN, {
      method: 'POST',
      headers: authHaders
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.token) {
          setAuthToken(data.token);
          onSubmitCall(e, data.token);
        } else if(data && data.message) {
          setSpecificErrorMsg(data.message);
        }
      })

      .catch(err => {
        setShowCommonError(true);
        setCommonErrorMsg(COMMON_API_ERROR);
        console.error(err);
      });
    } else {
      onInputEvent(e)
      if (isWindow) {
        window.top.postMessage("validationErr", "*");
      }
    }

  }

  const resetValues = () => {
    setAccNo("");
    setShowReqErrorMsg(false);
    setShowMinMaxErrorMsg(false);
    setShowCommonError(false);
    setSpecificErrorMsg('');
    inputRef.current.classList.remove('input-elm--border');
  }


  const setPopupBox = (e) => {
    if (isWindow) {
      window.top.postMessage("info", "*");//* should be replaced with domain name/origin name
    }
  }

  

  /*const showPopupBox = (e) => {
    let elm = document.querySelector('#PopupBoxInfo');
    if(elm && popupPos) {
      // elm.style.top = "-120px"
      console.log(popupPos, elm.offsetHeight);
    }
  }*/

  const validateInputAccountNo = (e) => {
    var inputVal = e.target.value.replace(/\D/g, "");
    setAccNo(DOMPurify.sanitize(inputVal));
  }

  const getIframeHeight = () => {
    let elm = document.querySelector('#gatsby-focus-wrapper');
    setTimeout(() => {
      let setIframeH = elm.getBoundingClientRect().height + 5 + 'px';
      if(isWindow) {
        window.top.postMessage({'iframeHeight':setIframeH}, "*");//* should be replaced with domain name/origin name
      }
    }, 100);
    
  }

  const onInputEvent = (e) => {
    setShowCommonError(false);
    if (accNo === "") {
      setShowReqErrorMsg(true);
      inputRef.current.classList.add('input-elm--border');
      setShowMinMaxErrorMsg(false);
      getIframeHeight();
    } else if (accNo.length < 5) {
      setShowMinMaxErrorMsg(true);
      setShowReqErrorMsg(false);
      inputRef.current.classList.add('input-elm--border');
      getIframeHeight();
    } else {
      setShowReqErrorMsg(false);
      setShowMinMaxErrorMsg(false);
      inputRef.current.classList.remove('input-elm--border');
    }
  }



  const onSubmitCall = (e, token) => {
    e.preventDefault();
    setSpecificErrorMsg('');
    if (accNo !== "" && !showReqErrorMsg && !showMinMaxErrorMsg) {
      let reqParams = { "bankAccountNumber": accNo, "companyCode": "0121" };
      tokenHeaders['Authorization'] = "Bearer " + token;
      fetch(path + GET_BANK_TOKEN, {
        method: 'POST',
        headers: tokenHeaders,
        body: JSON.stringify(reqParams)
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            setSpecificErrorMsg(data.message);
            getIframeHeight();
          } else if (data.errorCode !== "" || data.errorMessage !== "") {
            setCommonErrorMsg(data.errorMessage ? data.errorMessage : data.errorCode);
            getIframeHeight();
          } else {
            
            // setTokenData(data.tokenizedBankAccountNumber);
            // let event = new CustomEvent('myCustomEvent', {'token': data.tokenizedBankAccountNumber})
            //  window.parent.document.dispatchEvent(event);
            if (isWindow) {
              window.top.postMessage({'token':data.tokenizedBankAccountNumber}, "*") //* should be replaced with domain name/origin name
            }
          }
        })

        .catch(err => {
          setShowCommonError(true);
          setCommonErrorMsg(COMMON_API_ERROR);
          getIframeHeight();
          console.error(err);
        });
    }
  }


  return (
    <div>
      {/*<form onSubmit={onSubmitCall}>
      <label htmlFor="accountnum" className="lbl-elm">Account number</label>
      <div><input onInput={validateInputAccountNo} onBlur={onInputEvent} autoComplete='false' ref={inputRef} maxLength={accNoMax} value={accNo} className="input-elm" name="accountNum" type="text" id="InputAccNum" /></div>
      {showReqErrorMsg && <div className='errorMsg'>{ACCOUNT_NUMBER_VALIDATION_ERROR}</div>}
      {showMinMaxErrorMsg && <div className='errorMsg'>{ACCOUNT_NUMBER_LENGTH_VALIDATION_ERROR}</div>}
      {showCommonError && <div className='errorMsg'>{commonErrorMsg}</div>}
     <input type="submit" />
  </form>*/}
      <main className='main'> 
        <label htmlFor="accountNum" className="lbl-elm">Account Number<img alt="info" className='info-icon-img' onClick={setPopupBox} src="https://stg-my.reliant.com/resources/assets/images/oam/payment_new_cards_img/info-icon.png" /></label>
        <div><input onInput={validateInputAccountNo} onBlur={onInputEvent} autoComplete='off' ref={inputRef} maxLength={accNoMax} value={accNo} className="input-elm" name="accountNum" type="text" id="InputAccNum" /></div>
        {showReqErrorMsg && <div className='errorMsg'>{ACCOUNT_NUMBER_VALIDATION_ERROR}</div>}
        {showMinMaxErrorMsg && <div className='errorMsg'>{ACCOUNT_NUMBER_LENGTH_VALIDATION_ERROR}</div>}
        {showCommonError && <div className='errorMsg'>{commonErrorMsg}</div>}
        {specificErrorMsg && specificErrorMsg !== '' && <div className='errorMsg'>{specificErrorMsg}</div>}
        <button onClick={onSubmitCall} hidden={true} id='SubmitBtn'>Submit</button>
        {/*<div id="DataItemToken" data-value={tokenData}  hidden={true}></div>*/}
      </main>
    </div>
  )
}

export default IndexPage
